import React, { Component } from 'react';
import Navigation from "../components/Header/Navigation.js";
import PageTransition from 'gatsby-plugin-page-transitions';
import Footer from "../components/Footer/Footer.js";
import WorkWithUs from "../components/Global/Work_With_Us_Button.js";
import GDPR from '../components/Global/GDPR';
// import Loader from '../components/Global/Loader';
import SEO from '../components/seo.js';


export default class Legals extends Component {
	constructor(props){
        super(props);
        this.state = {
            isLoaded: this.props.pageContext.isLoaded,
			isLazyLoaded: this.props.pageContext.isLazyLoaded,
			isReady: this.props.pageContext.isReady,
			data: this.props.pageContext.data,
			cookieBanner: this.props.pageContext.cookieBanner,
			headerLinks: this.props.pageContext.header,
			footerLinks: this.props.pageContext.footer,
		}
	}
	componentDidMount() {
		
	}
    render() {
		let page_ready = this.state.isLoaded && this.state.isReady ? "page-ready page-loaded show-text" : "page-loading";
		if(this.state.isLoaded){

            return (
				<React.Fragment><PageTransition>
					<GDPR data={this.state.cookieBanner} />
				
				<SEO
					title={this.state.data.yoast_meta.yoast_wpseo_title}
					description={this.state.data.yoast_meta.yoast_wpseo_metadesc}
				/>
					<Navigation links={this.state.headerLinks} />
					<WorkWithUs />
	                <section className={"legals "+page_ready}>
						<div className="grid">
							<div className="grid-m-6 grid-t-10 push-t-1 grid-d-10 push-d-1">
	                    		<div dangerouslySetInnerHTML={{ __html: this.state.data.content.rendered }}></div>
							</div>
						</div>
						<Footer links={this.state.footerLinks} />
	                </section>
				</PageTransition></React.Fragment>
            )
        } else{
			return <section className="legals"></section>
		}
    }
}
